import styled from '@emotion/styled'
import { css } from '@styled-system/css'
import { Box } from 'components'

const Dot = styled(Box)(props =>
  css({
    borderRadius: '50%',
    size: props.size || '8px',
    alignSelf: props.align || 'normal',
  })
)

export { Dot }
