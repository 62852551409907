import React, { useState } from 'react'
import { Flex, Box, Container, Link, Logo, LanguageChanger } from 'components'
import MenuSvg from '../images/menu.svg'

const HeaderLink = ({ children, vertical = false, ...props }) => (
  <Box
    as={Link}
    activeClassName={'active'}
    color={'primary.dark'}
    fontFamily={'header'}
    fontSize={'header'}
    fontWeight={'header'}
    marginLeft={vertical ? '0' : 7}
    marginY={vertical ? 2 : '0'}
    textDecoration={'none'}
    transition={'all 0.2s ease-in-out'}
    styled={{
      '&:first-of-type': {
        marginLeft: '0',
      },
      ':hover': {
        color: 'primary.pink',
      },
      '&.active': {
        color: 'primary.pink',
      },
    }}
    {...props}
  >
    {children}
  </Box>
)

const HeaderWide = ({ menu, ...props }) => {
  return (
    <Container {...props}>
      <Flex
        as={'nav'}
        alignItems={'center'}
        paddingX={{ _: 8, md: '0' }}
        paddingY={4}
      >
        <Flex width={1 / 3} justifyContent={'flex-start'}>
          {menu.left.map(item => (
            <HeaderLink to={item.link} key={item.link}>
              {item.name}
            </HeaderLink>
          ))}
        </Flex>

        <Flex as={Link} to={'/'} width={1 / 3} justifyContent={'center'}>
          <Logo />
        </Flex>

        <Flex width={1 / 3} justifyContent={'flex-end'}>
          {menu.right.map(item => (
            <HeaderLink to={item.link} key={item.link}>
              {item.name}
            </HeaderLink>
          ))}
          <LanguageChanger marginLeft={7} />
        </Flex>
      </Flex>
    </Container>
  )
}

const HeaderSlim = ({ menu, ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <Container {...props} paddingY={2} paddingX={8}>
      <Flex as={'nav'} flexDirection={'row'} height={'64px'}>
        <Flex
          as={Link}
          to={'/'}
          width={1 / 2}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Logo height={'40px'} />
        </Flex>
        <Flex
          width={1 / 2}
          stroke={'primary.black'}
          justifyContent={'flex-end'}
        >
          <MenuSvg width={'20px'} onClick={e => setMenuOpen(!menuOpen)} css={{userSelect: 'none'}} />
        </Flex>
      </Flex>
      {menuOpen && (
        <Flex flexDirection={'column'} paddingBottom={2}>
          {menu.left.concat(menu.right).map(item => (
            <HeaderLink to={item.link} key={item.link} vertical>
              {item.name}
            </HeaderLink>
          ))}
          <LanguageChanger marginTop={2} />
        </Flex>
      )}
    </Container>
  )
}

const Header = ({ menu }) => {
  return (
    <React.Fragment>
      <HeaderWide menu={menu} display={{ _: 'none', md: 'flex' }} />
      <HeaderSlim menu={menu} display={{ _: 'flex', md: 'none' }} />
    </React.Fragment>
  )
}

export { Header }
