import React, { useContext } from 'react'

import { Flex, Box, Logo, LanguageContext } from 'components'
import locales from '../config/locales'

const Footer = () => {
  const language = useContext(LanguageContext)
  const locale = locales[language]
  return (
    <Box as={'footer'} marginTop={'1em'} marginBottom={'1em'}>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={{ _: 'column', sm: 'row' }}
        backgroundColor={'neutral.0'}
        color={'neutral.5'}
        padding={'2em'}
        borderTopWidth={'2px'}
        borderTopStyle={'solid'}
        borderTopColor={'primary.purple'}
      >
        <Box lineHeight={2}>Joëlle Roy</Box>
        <Box display={{ _: 'none', sm: 'inline' }} marginX={3}>
          {'-'}
        </Box>
        <Box
          as={'a'}
          color={'neutral.5'}
          textDecoration={'none'}
          lineHeight={2}
          href={`tel:${locale.phone}`}
          styled={{
            '&:hover': {
              color: 'primary.pink',
            },
          }}
        >
          {locale.phone}
        </Box>
        <Box display={{ _: 'none', sm: 'inline' }} marginX={3}>
          {'-'}
        </Box>
        <Box
          as={'a'}
          color={'neutral.5'}
          textDecoration={'none'}
          lineHeight={2}
          href={`mailto:${locale.email}`}
          styled={{
            '&:hover': {
              color: 'primary.pink',
            },
          }}
        >
          {locale.email}
        </Box>
      </Flex>

      <Flex
        flexDirection={'column'}
        minHeight={'160px'}
        backgroundColor={'primary.white'}
        color={'neutral.1'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box marginTop={5}>
          <Logo />
        </Box>
        <Box textAlign={'center'} marginX={8} marginY={3}>
          {locale.footer.definition}
        </Box>
      </Flex>
    </Box>
  )
}

export { Footer }
