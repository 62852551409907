import React from 'react'
import * as R from 'ramda'
import { Flex, Box } from 'components'

const Header = ({ as = 'h1', textAlign = 'center', children, ...props }) => (
  <Box
    as={as}
    fontSize={{ _: '24px', sm: '32px' }}
    textAlign={textAlign}
    fontFamily={'heading'}
    {...props}
  >
    {children}
  </Box>
)

const SectionBox = ({ children, ...props }) => (
  <Box marginX={8} marginTop={6} {...props}>
    {children}
  </Box>
)

const TextSection = ({ title, items }) => {
  return (
    <SectionBox>
      <Header>{title}</Header>
      {items.map((item, idx) => (
        <Box as={'p'} key={idx} marginY={4}>
          {item}
        </Box>
      ))}
    </SectionBox>
  )
}

const ListSection = ({ title, items }) => {
  return (
    <SectionBox>
      <Header as={'h2'} textAlign={'left'} fontSize={'1.2em'}>
        {title}
      </Header>
      <Box
        as={'ul'}
        marginTop={2}
        css={{ paddingInlineStart: '20px', listStyleType: 'circle' }}
      >
        {items.map((item, idx) => (
          <Box as={'li'} key={idx} marginY={1}>
            {item}
          </Box>
        ))}
      </Box>
    </SectionBox>
  )
}

const SubSections = ({ title, items }) => {
  return (
    <SectionBox>
      <Header>{title}</Header>
      {items.map(({ title, items }, idx) => (
        <Box key={idx} marginY={4}>
          {title && (
            <Header as={'h2'} fontSize={'1.1em'} textAlign={'left'}>
              {title}
            </Header>
          )}
          {items.map((item, idx) => (
            <Box as={'p'} key={idx} marginTop={2}>
              {item}
            </Box>
          ))}
        </Box>
      ))}
    </SectionBox>
  )
}

const Images = R.compose(
  R.intersperse(<Box width={2 / 12} height={'40px'} key={'spacer'} />),
  R.map(({ src, alt = '' }) => (
    <Box
      as={'img'}
      key={src}
      src={src}
      alt={alt}
      width={{ _: '100%', md: 5 / 12 }}
      height={{ _: 'auto', md: '248px' }}
    />
  ))
)

const ImageSection = ({ images }) => (
  <Flex
    marginTop={'3em'}
    marginBottom={'2em'}
    paddingX={8}
    flexDirection={{ _: 'column', md: 'row' }}
  >
    {Images(images)}
  </Flex>
)

const Section = ({ type, ...props }) => {
  switch (type) {
    case 'text':
      return <TextSection {...props} />
    case 'list':
      return <ListSection {...props} />
    case 'subsections':
      return <SubSections {...props} />
    case 'images':
      return <ImageSection {...props} />
    default:
      return null
  }
}

export { Section }
