import coachingImage from 'images/home-coaching.jpg'
import yogaImage from 'images/home-yoga.jpg'

export default {
  siteTitle: 'Sankalpa CH',
  email: 'roy@sankalpa.ch',
  phone: '+41 (0)77 419 05 65',
  address: ['Centre Aliotis', '30 Boulevard Helvétique', '1207 Genève'],
  menu: {
    left: [
      { link: '/coaching', name: 'Coaching' },
      { link: '/yoga', name: 'Yoga' },
      { link: '/guidance', name: 'Guidance' },
    ],
    right: [
      { link: '/about', name: 'About' },
      { link: '/contact', name: 'Contact' },
    ],
  },
  snippets: {
    videoConference: 'video conference',
  },
  footer: {
    definition: `Sankalpa is the committed intention placed in the heart, inspired by a higher truth, for the purpose of fulfilling our life's mission`,
  },
  pages: {
    home: {
      title: 'Home',
      hero1: 'Open the realm of possibilities',
      hero2: 'Create harmony',
      bodyTitle: 'Your life is a precious gift, make it something wonderful',
      bodyText: `In the face of life's challenges, we sometimes need someone on our side
        to help us find clarity, reconnect with what truly matters and find the
        resources to move forward. Broaden your vision and make your way to a
        meaningful life fully in tune with the aspirations of your true self.`,
      topicBoxes: [
        {
          title: 'Coaching',
          link: '/coaching',
          text: `The coaching that I offer will help you to find concrete solutions to
          the challenges that you are facing. Whether you have difficulties in a
          professional, relational, or personal area, coaching is an efficient
          means to help you move out of circumstances where you feel stuck,
          limited or confused.`,
          image: coachingImage,
        },
        {
          title: 'Yoga',
          link: '/yoga',
          text: `The Yoga that I propose will help you to apprehend life with more
          ease, fluidity and strength. Whether you need to take care of your
          health, experience more serenity or go on a path of discovery yoga is
          a wonderful way to find healthy and lasting answers.`,
          image: yogaImage,
        },
      ],
    },
    coaching: {
      whatIsCoaching: {
        title: 'What is coaching',
        items: [
          `Coaching is an amazing ally when we go through life transitions. It is also aimed at anyone who wants to move forward and go to the best of oneself, develop their potential, and create a life that matches their aspirations.`,
          `The coach accompanies the person, helping them to mobilize their energy, skills and talents in order to achieve their goals. They will be helped to clarify their situations, acquire new resources and become more performant. It is a tool for development and harmonization, which takes into account the values ​​and personal needs of the individual.`,
          `Coaching assumes that all the answers and resources are within the coachee. It is through listening, questioning and precise tools that the coach will help make solutions emerge. The coach is a companion, not a counselor or someone who gives directives. He helps the individual to find what is right, good and fruitful for himself.`,
        ],
      },
      howIsASession: {
        title: 'What is a session like',
        items: [
          'A coaching session lasts approximately 1 hour. Depending on the subject, it can take 7 to 9 sessions. Sometimes one or two appointments are enough. Generally they take place about once a week or every two weeks, as needed.',
          'It is a time set a part to the person where the coach, by listening and questioning, accompanies the coachee in a journey. He uses very specific tools chosen according to the needs of the person.',
          'In the first sessions the aim is to explore the present situation, to have a global vision and to seek to clarify the current challenges.',
          'After this first (stage – step) , the coach helps his client to set clear goals, in line with what is important to him. If obstacles present themselves, the coach will help the person overcome them by helping them connect to and develop their resources.',
          'With the help of the coach, the coachee will have a clear idea of ​​what are the (stakes) of his choices and will be able to define the steps that will enable him to successfully achieve his goals.',
        ],
      },
      whatCanCoachingGiveYou: {
        title: 'What can coaching give you',
        items: [
          'The coaching that I offer will help you to find concrete solutions to the challenges that you are facing.  Whether you have difficulties in a professional, relational, or personal area, coaching is an efficient means to help you move out of circumstances where you feel stuck, limited or confused.',
        ],
      },
      togetherWeWillBeAbleTo: {
        title: 'Together we will be able to',
        items: [
          'Clarify your situation',
          'Define specific objectives',
          'Mobilize and develop your resources to reach your goals',
          'Overcome obstacles and limitations',
        ],
      },
      butAlso: {
        title: 'But also',
        items: [
          'Help you connect to your true desires and aspirations',
          'Discover your talents',
          'Recognize your values ​​and what truly matters to you',
          'Untangle internal conflicts and external difficulties',
        ],
      },
      myCoachingWillBringYou: {
        title: 'My coaching will bring you',
        items: [
          'A clear vision of your situation',
          'Solutions to find harmony',
          'Support in a transition period',
          'Help in a difficult stage',
          'Assistance to pick the best choices',
          'Concrete and precise tools for better health',
        ],
      },
    },
    yoga: {
      yoga: {
        title: 'Yoga',
        items: [
          {
            items: [
              `Yoga means union. It is the union between body, soul and spirit. Union with the Divine, our higher self and the consciousness of everything around us. It is the union between breath, movement and presence. It is this union that creates harmony.`,
              `Yoga is a practice that has many facets. It is accessible to everyone. To practice yoga is to take care of yourself truly listening with kindness and respect.`,
            ],
          },
          {
            title: 'Hatha-vinyasa yoga',
            items: [
              `Hatha-vinyasa yoga is a rather yang practice which is dynamic and gentle at the same time. Its benefits are remarkable on the physical, mental, emotional and spiritual levels. It allows you to align your posture, strengthen your muscles, densify your bones, soften and improve your concentration. It also allows you to be more in tune with yourself. Beyond the physical level, you can eliminate negative mental patterns and progress to moving meditation with a calm and clear mind.`,
            ],
          },
          {
            title: 'Yin yoga',
            items: [
              `Yin yoga is a mindfulness meditative practice that calms the nervous system. Its specificity is to work in stillness, each posture being held for about five minutes, so as to cleanse the energy channels through acupressure. It regenerates the organs by stimulating the meridians. This practice targets the joints, the bones and works the connective tissues in depth and allows the vital energy to circulate better.`,
            ],
          },
          {
            title: 'Guided meditation',
            items: [
              `The guided meditation that I offer is a moment of intense relaxation that allows you to connect to your interiority. It’s a time to let go of physical and emotional tension and incorporate the changes you create in your life with more fluidity. It is also a space for healing and energy alignment.`,
            ],
          },
        ],
      },
      health: {
        title: 'Health',
        items: [
          `When we desire to attain a health goal often we'll seek some wise advice. What we want is not an all fit programm but a process that suits us personally at all levels that we can follow through long term and give us the lasting reslult we desire.`,
          `In using coaching, i will seek to consider all the areas of your life ( your needs, your priorities, your schedule, your taste, your habits,etc...) discern which are the things that are keeping you from being where you want and and setting up a plan that will match your physiology, your schedule and the changes you are willing to make.`,
          `Together we will find the routine that fits you including the right eating habits, the kind of exercice you need, how to take care of yourself and the mindset you need to have.`,
        ],
      },
      body: {
        title: 'Body',
        items: [
          `Our body responds to all our thoughts, emotions and experiences. Every cell in our bodies have a  memory. In connecting with our body and loving it as it deserves we engage in a deeply healing process.`,
          `If we want to become  the best version of ourselves, we must absolutely take care of our bodies.`,
          `Having confidence in life is also something that translates into the body. We can work from the head into the body or from the body into the head. I like to work both ways.`,
          `Being a certified Yoga teacher and having experience and education with movement, (dance, pilates, physical theatre, improvisation) I want to help people finding a healthy balance in their bodies. I want to help them feel connected deep within.`,
          `My desire is to work together in personal sessions and after that i can give some specific exercices to do alone to improve ones posture, release emotions and stress, strengthen the body where it needs to, improve flexibility and get greater confidence. I also want to help people to find a greater freedom and fluidity in their movements to enhance their presence and enjoyement of life.`,
          `When doing a coaching session, one becomes aware of so many things, There is a magical dimension when all this mental and emotionnal process can be digested and incarnated deeply into the body.`,
          `On demand there's a possibility to do a small group session. It can be a Yoga session or a physical expression session. Either way I will create a time that would fit the needs of the participants including warm ups, meditations, movements and on demand improvisation in order for people to experience a greater sense of freedom, a higher confidence and explore their own creativity.`,
        ],
      },
    },
    guidance: {
      supportForHealing: {
        title: 'Support for healing',
        items: [
          `During the course of our lifes, it often happens that we experience very intense emotions and that these have not been fully lived out. These emotions then got crystallized in the body and are keeping us from living fully and freely.`,
          `The support that I offer takes into account the whole being, which is why I like to use different approaches adapted to the needs of each individual. In the emotional release practices offered, I remain highly attentive to the needs of the people and ensures that great care is taken at all stages of the process. An integration is planned at the end of these sessions and also a debriefing scheduled in order to define the next step.`,
        ],
      },
      alchemicalBreath: {
        title: 'Alchemical Breath',
        items: [
          `Alchemy of Breath® is a sustained, conscious and connected breathing practice that allows deep physical and energetic cleansing. This ancestral practice intensifies our energy level and leads to a transformation of the emotions stored in the body by releasing cellular memories. It allows a deep exploration of oneself by accessing an expanded state of consciousness in a natural way, simply by breathing.`,
          `The Alchemical Breathwork offers the possibility of entering into a relationship with the depths of the unconscious, bringing out information from the physical, emotional, mental and spiritual levels. This opening of consciousness activates our natural capacity for self-healing by allowing us to relive, integrate and transform the experiences and memories that are at the source of our suffering. Just as alchemy aims to transform lead into gold, Alchemical Breathwork teaches us to transmute our heavy and painful energies into free and radiant energies.`,
        ],
      },
      metatherapy: {
        title: 'Metatherapy',
        items: [
          `Metatherapy is a practice that allows us to go in full consciousness to meet our areas of suffering and to transform these emotional wounds. It is a process of personal introspection that goes through bodily feelings. It is a concrete and pragmatic method that acts deep within us. This is why it provides an fast, effective and stable solution over time.`,
          `The practitioner in metatherapy accompanies the person to go by himself in his feelings. He shows him how to untie, resolve and transform areas of suffering with acceptance, forgiveness. It is a work of self-love that will unblock and realign us to our true potential.`,
          `And more than anything, metatherapy teaches us the keys to transforming the energies and emotions stuck inside of us. Once one understands the practice, one can become independent in their work. It is a process of self empowerment as much as healing and awakening.`,
        ],
      },
      intuitiveReadings: {
        title: 'Intuitive readings',
        items: [
          `In this practice, I connect to my divine guidance and to your energy in order to obtain clarification and encouragement for your life journey and your particular situation. This is not about prediction but more of an energy reading. I use oracle and tarot cards to support this work. Part of the reading is done through intuition and the energies of the cards and some messages are directly channeled. These readings aim to help you to realign yourself, connect to your own guidance and return to a state of serenity regarding your life path.`,
        ],
      },
    },
    about: {
      vision: {
        title: 'Vision',
        items: [
          `I strongly believe that each and every individual have treasures hidden within and an exclusive combination of talents that when expressed will allow them to feel true satisfaction and that their unique expression will bring an amazing contribution to the world. I believe that a truly successful life, is a fulfilling life and therefore it must be filled with harmony. We are multifaceted and complex beings where different dimensions are constanly trying to find the right balance.`,
          `In order to move forward with agility and harmony, we need to become aware of our body soul spirit algnement and consider all aspects of our beings and our lives and nurture them appropriately according to our current priorities. We can not afford to neglect part of ourselves or our existence.`,
          `Sometimes our lives simply need a few small adjustments to find a form of balance and other times larger changes are needed. Each individual knows inside what the best solution is and the most appropriate path. It's a real passion for me to help people connect with their interiority, to embrace their true identity, discover new possibilities, dare to move forward and become fully alive.`,
        ],
      },
      biography: {
        title: 'Biography',
        items: [
          `Having always been fascinated by the deep mystery of existence, I was very early on in search of a harmonious and meaningful life. It’s been a long path of exploration and transformation. Passionate about personal development, spirituality and psychology, I drew from these three areas the necessary resources to move towards the best of myself.`,
          `At various times I have had the opportunity to teach and create spaces for people to reconnect with themselves and to overcome certain limitations. In 2014 I decided to train myself professionally to be able to transmit to others the richness accumulated along my quest. I started with taking a yoga teacher training, because for me, a life of meaning begins with an authentic inner connection. I also note the importance of a coherence between the inner experience and how it is expressed and manifested on the external.`,
          `I am fascinated by the direct interaction that there is between the body the soul is the spirit. Also, I see the importance to consider the person as a whole. I continued my training with NLP and coaching and discovered new horizons of opportunities for change. Through my studies and my experience, I have acquired good knowledge in health and nutrition. Throughout this journey, I am happy to have found my path to this beautiful harmony and authentic connection to my essence and what gives meaning to my life.`,
          `It is an honor today for me to be able to accompany people on their life path and help them find all the resources they need to become the best of themselves and create the life that suits them who they truly are and that will satisfy them fully.`,
        ],
      },
    },
    contact: {
      pageHeading:
        'Give yourself the gift of becoming the best version of you and creating a life that fully resonates with who you are.',
      contactHeading: 'Contact',
      emailHeading: 'Email',
      phoneAndWhatsappHeading: 'Phone and whatsapp',
      addressHeading: 'Address',
      pricesHeading: 'Prices',
      pricesSubheading:
        'Please do not hesitate to contact me for any further questions or details. If you wish, we can arrange a 15 minute session by videoconference without obligation on your part.',
      prices: [
        {
          type: 'session',
          text: '1 coaching session',
          duration: '1 hour',
          price: '120',
        },
        {
          type: 'session',
          text: 'Package of 7 coaching sessions',
          duration: '1 hour',
          price: '770',
        },
        {
          type: 'session',
          text: '1 coaching session and guided meditation',
          duration: '1 hour 30 minutes',
          price: '160',
        },
        {
          type: 'session',
          text: 'Package of 7 coaching sessions and guided meditation',
          duration: '1 hour 30 minutes',
          price: '1020',
        },
        {
          type: 'package',
          title:
            'Serenity Package: Specially designed for a health goal and / or for managing stress and emotions',
          price: '1070',
          sessions: [
            { text: '5 coaching sessions', duration: '1 hour' },
            {
              text: '5 yoga sessions, breathing and guided meditation',
              duration: '1 hour',
            },
            {
              text: '1 counseling session',
              duration: '45 minutes',
              video: true,
            },
            {
              text: '2 debriefing sessions + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'package',
          title:
            'Harmony Package: Specially designed to take hold of your life',
          price: '1400',
          sessions: [
            { text: '7 coaching sessions', duration: '1 hour' },
            { text: '5 yoga sessions', duration: '1 hour' },
            { text: '2 guided meditation sessions', duration: '1 hour' },
            {
              text: '1 counseling session',
              duration: '45 minutes',
              video: true,
            },
            {
              text: '3 debriefing sessions + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'package',
          title:
            'Light Package: Specially designed for a major change of life and / or a new important goal',
          price: '1600',
          sessions: [
            { text: '8 coaching sessions', duration: '1 hour' },
            { text: '8 yoga sessions', duration: '1 hour' },
            { text: '3 guided meditation sessions', duration: '1 hour' },
            {
              text: '4 debriefing sessions + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'session',
          text: '1 individual yoga class',
          duration: '1 hour',
          price: '80',
        },
      ],
      houseCall:
        'Possibility of giving small group lessons on request (price to be agreed)',
      contactForPrice: `For sessions of alchemical breath, metatherapy and intuitive reading contact me privately to agree on the formula best suited to your needs.`,
    },
  },
}
