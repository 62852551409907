import React, { useContext } from 'react'
import { LanguageContext } from 'components'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ to, children, className, ...rest }) => {
  const language = useContext(LanguageContext)

  return (
    <GatsbyLink
      className={className}
      to={`/${language}${to}`}
      partiallyActive={true}
      {...rest}
    >
      {children}
    </GatsbyLink>
  )
}

export { Link }
