const colors = {
  primary: {
    black: 'hsla(0, 0%, 0%, 1)',
    white: 'hsla(0, 0%, 100%, 1)',
    purple: 'hsla(318, 99%, 36%, 1)',
    pink: 'hsla(318, 85%, 85%, 1)',
    dark: 'hsla(318, 99%, 10%, 1)',
  },
  neutral: [
    'hsla(318, 0%, 10%, 1)',
    'hsla(318, 0%, 20%, 1)',
    'hsla(318, 0%, 40%, 1)',
    'hsla(318, 0%, 60%, 1)',
    'hsla(318, 0%, 80%, 1)',
    'hsla(318, 0%, 90%, 1)',
  ],
  hover: {
    black: 'hsla(0, 0%, 0%, 1)',
    white: 'hsla(0, 0%, 100%, 1)',
    purple: 'hsla(318, 99%, 26%, 1)',
    pink: 'hsla(318, 75%, 45%, 1)',
  },
}

const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64]
const breakpoints = ['640px', '832px', '960px', '1280px']
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const sizes = {}

sizes.container = 960

const fonts = {
  body: 'Open Sans, sans-serif',
  header: 'Raleway, sans-serif',
  heading: 'Roboto, sans-serif',
}
const fontWeights = [300, 400, 600, 700, 800]
fontWeights.header = 300
const fontSizes = [14, 16, 24, 32]
fontSizes.header = 18

// TODO: find out what weights we are using
const googleFonts = [
  'Open+Sans:300,400,600,700',
  'Raleway:300,500,700,800',
  'Roboto:300',
]

const outlineColor = `hsla(318, 0%, 45%, 0.6)`

const shadows = {
  hero: `-1px -1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor}, -1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor}, 1px 1px 2px ${colors.primary.pink}`,
  dot: `1px 1px 2px ${colors.neutral[0]}`,
}

export default {
  colors,
  space,
  breakpoints,
  sizes,
  fonts,
  fontWeights,
  fontSizes,
  googleFonts,
  shadows,
}
