/**
 * Based around the reflexbox package
 * https://github.com/rebassjs/rebass/blob/master/packages/reflexbox
 */
import * as R from 'ramda'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { system } from '@styled-system/core'
import systemPropTypes, { createPropTypes } from '@styled-system/prop-types'
import {
  default as styledShouldForwardProp,
  createShouldForwardProp,
} from '@styled-system/should-forward-prop'

import {
  color,
  layout,
  typography,
  flexbox,
  border,
  background,
  position,
} from 'styled-system'

/**
 * XXX: for some reason we are getting import errors
 * in Webstorm when importing from parent package
 * so include some packages explicitly T.T
 */
import { space } from '@styled-system/space'
import { shadow } from '@styled-system/shadow'
import { compose } from '@styled-system/core'

// list of props that should or should not be forwarded to elements
const goodProps = ['getProps', 'activeClassName']
const badProps = ['label']
// TODO: can we use something like R.complement
// to invert createShouldForwardProps?
const customShouldForwardProp = (prop) => R.any(R.equals(prop), goodProps)
const customShouldNotForwardProp = createShouldForwardProp(badProps)
// TODO: honestly ... this is embarrasing T.T
const shouldForwardProp = (prop) => {
  if (customShouldForwardProp(prop)) {
    return true
  }
  if (!customShouldNotForwardProp(prop)) {
    return false
  }
  return styledShouldForwardProp(prop)
}

const customPropNames = {
  fill: {
    property: 'fill',
    scale: 'colors',
  },
  stroke: {
    property: 'stroke',
    scale: 'colors',
  },
  label: true,
  transform: true,
  transition: true,
  textTransform: true,
  textDecoration: true,
  backgroundAttachment: true,
}

const customProps = system(customPropNames)
const customPropTypes = createPropTypes(Object.keys(customPropNames))

const styledProp = (props) => css(props.styled)(props.theme)
const cssProp = (props) => props.css

const Box = styled('div', {
  shouldForwardProp,
})(
  {
    boxSizing: 'border-box',
    minWidth: 0, // shrink below 0 when used as flex item
    margin: 0,
  },
  customProps,
  styledProp,
  cssProp,
  compose(
    space,
    color,
    layout,
    typography,
    flexbox,
    border,
    background,
    position,
    shadow
  )
)

/**
 * To provide proper completion we expand all the properties
 * we are exposing with these helpers directly here. However,
 * to encourage using full property names rather than shorthand
 * we are only providing prop types for the full properties.
 */
const boxPropTypes = {
  // SPACE
  margin: systemPropTypes.space.margin,
  marginTop: systemPropTypes.space.marginTop,
  marginRight: systemPropTypes.space.marginRight,
  marginBottom: systemPropTypes.space.marginBottom,
  marginLeft: systemPropTypes.space.marginLeft,
  marginX: systemPropTypes.space.marginX,
  marginY: systemPropTypes.space.marginY,
  // m: propTypes.space.m,
  // mt: propTypes.space.mt,
  // mr: propTypes.space.mr,
  // mb: propTypes.space.mb,
  // ml: propTypes.space.ml,
  // mx: propTypes.space.mx,
  // my: propTypes.space.my,
  padding: systemPropTypes.space.padding,
  paddingTop: systemPropTypes.space.paddingTop,
  paddingRight: systemPropTypes.space.paddingRight,
  paddingBottom: systemPropTypes.space.paddingBottom,
  paddingLeft: systemPropTypes.space.paddingLeft,
  paddingX: systemPropTypes.space.paddingX,
  paddingY: systemPropTypes.space.paddingY,
  // p: propTypes.space.p,
  // pt: propTypes.space.pt,
  // pr: propTypes.space.pr,
  // pb: propTypes.space.pb,
  // pl: propTypes.space.pl,
  // px: propTypes.space.px,
  // py: propTypes.space.py,

  // COLOR
  color: systemPropTypes.color.color,
  backgroundColor: systemPropTypes.color.backgroundColor,
  opacity: systemPropTypes.color.opacity,
  // bg: propTypes.color.bg,

  // LAYOUT
  width: systemPropTypes.layout.width,
  height: systemPropTypes.layout.height,
  minWidth: systemPropTypes.layout.minWidth,
  minHeight: systemPropTypes.layout.minHeight,
  maxWidth: systemPropTypes.layout.maxWidth,
  maxHeight: systemPropTypes.layout.maxHeight,
  size: systemPropTypes.layout.size,
  overflow: systemPropTypes.layout.overflow,
  overflowX: systemPropTypes.layout.overflowX,
  overflowY: systemPropTypes.layout.overflowY,
  display: systemPropTypes.layout.display,
  verticalAlign: systemPropTypes.layout.verticalAlign,

  // TYPOGRAPHY
  fontFamily: systemPropTypes.typography.fontFamily,
  fontSize: systemPropTypes.typography.fontSize,
  fontWeight: systemPropTypes.typography.fontWeight,
  lineHeight: systemPropTypes.typography.lineHeight,
  letterSpacing: systemPropTypes.typography.letterSpacing,
  textAlign: systemPropTypes.typography.textAlign,
  fontStyle: systemPropTypes.typography.fontStyle,

  // FLEXBOX
  alignItems: systemPropTypes.flexbox.alignItems,
  alignContent: systemPropTypes.flexbox.alignContent,
  justifyItems: systemPropTypes.flexbox.justifyItems,
  justifyContent: systemPropTypes.flexbox.justifyContent,
  flexWrap: systemPropTypes.flexbox.flexWrap,
  flexDirection: systemPropTypes.flexbox.flexDirection,
  flex: systemPropTypes.flexbox.flex,
  flexGrow: systemPropTypes.flexbox.flexGrow,
  flexShrink: systemPropTypes.flexbox.flexShrink,
  flexBasis: systemPropTypes.flexbox.flexBasis,
  justifySelf: systemPropTypes.flexbox.justifySelf,
  alignSelf: systemPropTypes.flexbox.alignSelf,
  order: systemPropTypes.flexbox.order,

  // BORDER
  border: systemPropTypes.border.border,
  borderWidth: systemPropTypes.border.borderWidth,
  borderStyle: systemPropTypes.border.borderStyle,
  borderColor: systemPropTypes.border.borderColor,
  borderRadius: systemPropTypes.border.borderRadius,
  borderTop: systemPropTypes.border.borderTop,
  borderTopLeftRadius: systemPropTypes.border.borderTopLeftRadius,
  borderTopRightRadius: systemPropTypes.border.borderTopRightRadius,
  borderRight: systemPropTypes.border.borderRight,
  borderBottom: systemPropTypes.border.borderBottom,
  borderBottomLeftRadius: systemPropTypes.border.borderBottomLeftRadius,
  borderBottomRightRadius: systemPropTypes.border.borderBottomRightRadius,
  borderLeft: systemPropTypes.border.borderLeft,
  borderX: systemPropTypes.border.borderX,
  borderY: systemPropTypes.border.borderY,
  borderTopWidth: systemPropTypes.border.borderTopWidth,
  borderTopColor: systemPropTypes.border.borderTopColor,
  borderTopStyle: systemPropTypes.border.borderTopStyle,
  borderBottomWidth: systemPropTypes.border.borderBottomWidth,
  borderBottomColor: systemPropTypes.border.borderBottomColor,
  borderBottomStyle: systemPropTypes.border.borderBottomStyle,
  borderLeftWidth: systemPropTypes.border.borderLeftWidth,
  borderLeftColor: systemPropTypes.border.borderLeftColor,
  borderLeftStyle: systemPropTypes.border.borderLeftStyle,
  borderRightWidth: systemPropTypes.border.borderRightWidth,
  borderRightColor: systemPropTypes.border.borderRightColor,
  borderRightStyle: systemPropTypes.border.borderRightStyle,

  // BACKGROUND
  background: systemPropTypes.background.background,
  backgroundImage: systemPropTypes.background.backgroundImage,
  backgroundSize: systemPropTypes.background.backgroundSize,
  backgroundPosition: systemPropTypes.background.backgroundPosition,
  backgroundRepeat: systemPropTypes.background.backgroundRepeat,

  // POSITION
  position: systemPropTypes.position.position,
  zIndex: systemPropTypes.position.zIndex,
  top: systemPropTypes.position.top,
  right: systemPropTypes.position.right,
  bottom: systemPropTypes.position.bottom,
  left: systemPropTypes.position.left,

  // SHADOW
  boxShadow: systemPropTypes.shadow.boxShadow,
  textShadow: systemPropTypes.shadow.textShadow,

  // CUSTOM
  fill: customPropTypes.fill,
  stroke: customPropTypes.stroke,
  label: customPropTypes.label,
  transform: customPropTypes.transform,
  backgroundAttachment: customPropTypes.backgroundAttachment,
  textDecoration: customPropTypes.textDecoration,
  textTransform: customPropTypes.textTransform,
  transition: customPropTypes.transition,
}

Box.propTypes = boxPropTypes

const Flex = styled(Box)((props) => ({
  display: props.display || 'flex',
  flex: props.flex || '1 1 auto',
}))
Flex.propTypes = boxPropTypes

export { Flex, Box }
