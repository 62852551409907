import coachingImage from 'images/home-coaching.jpg'
import yogaImage from 'images/home-yoga.jpg'

export default {
  siteTitle: 'Sankalpa CH',
  email: 'roy@sankalpa.ch',
  phone: '+41 (0)77 419 05 65',
  address: ['Centre Aliotis', '30 Boulevard Helvétique', '1207 Genève'],
  menu: {
    left: [
      { link: '/coaching', name: 'Coaching' },
      { link: '/yoga', name: 'Yoga' },
      { link: '/guidance', name: 'Guidance' },
    ],
    right: [
      { link: '/about', name: 'À propos' },
      { link: '/contact', name: 'Contact' },
    ],
  },
  snippets: {
    videoConference: 'video conférence',
  },
  footer: {
    definition:
      'Sankapla est l’intention engagée placée dans le cœur, inspirée par une vérité supérieure, dans le but de remplir la mission de notre âme',
  },
  // TODO: remove and rename hero text
  pages: {
    home: {
      title: 'Home',
      hero1: 'Ouvrez le champ des possibles',
      hero2: 'Créez l’harmonie',
      bodyTitle:
        'Votre vie est un cadeau précieux, faites en quelque chose de merveilleux',
      bodyText: `Face aux défis de la vie, nous avons parfois besoin de quelqu’un à
        nos côté pour nous aider à retrouver la clarté afin de nous reconnecter à ce
        qui a réellement de l’importance. L’autre pourra être un allié nous permettant
        de trouver les ressources nécessaires pour aller de l’avant. Osez élargir votre
        vision et frayez-vous un chemin vers une vie pleine de sens et en accord avec
        les aspirations votre être profond.`,
      topicBoxes: [
        {
          title: 'Coaching',
          link: '/coaching',
          text: `Le coaching que je vous propose vous aidera à trouver des solutions
            concrètes face aux défis qui se présentent à vous. Que ce soit des difficultés
            personnelles, relationnelles ou professionnelles auxquelles vous êtes confrontés,
            le coaching est un moyen efficace pour vous aider à sortir de situations où
            vous vous sentez bloqué, limité ou confus.`,
          image: coachingImage,
        },
        {
          title: 'Yoga',
          link: '/yoga',
          text: `Le Yoga que je vous propose vous aidera à appréhender la vie avec plus
          d’aisance, de fluidité et de force. Que vous ayez besoin de prendre soin de
          votre santé, de ressentir plus de sérénité ou d’entamer un chemin de découvertes,
          le yoga est un excellent moyen de trouver des réponses saines, profondes
          et durables.`,
          image: yogaImage,
        },
      ],
    },
    coaching: {
      whatIsCoaching: {
        title: 'Qu’est-ce que le coaching',
        items: [
          `Le coaching est un allié extraordinaire lorsque nous traversons des transitions de vies. Il s'adresse également à toute personne qui souhaite avancer et aller vers le meilleur de soi-même, développer son potentiel, et créer une existence à la hauteur de ses aspirations.`,
          `Le coach est un accompagnateur, il n’est pas un conseiller, ni quelqu’un qui va donner des directives. Il aide l’individu à trouver ce qui est juste, bon et fructueux pour lui.`,
          `C’est un temps mis à disposition de la personne où le coach, par son écoute et son questionnement, accompagne le coaché dans un cheminement. Il utilise des outils très précis choisis en fonction des besoins de la personne.`,
          // `Le coaching s'adresse à toute personne souhaitant avancer dans sa vie et aller vers le meilleur de soi-même.`,
          // `Le coach accompagne la personne, l'aidant à mobiliser son énergie, ses compétences et ses talents, afin d'atteindre ses objectifs. Il va l'aider à clarifier des situations, acquérir de nouvelles ressources et devenir plus performant.`,
          // `C'est un outil de développement et d’harmonisation, qui tient compte des valeurs et des besoins personnels de l'individu.`,
          // `Le coaching part du principe que toutes les réponses et les ressources sont à l’intérieur du coaché. C’est par l’écoute, le questionnement et des outils précis que le coach va aider à faire emmerger toutes les solutions.`,
          // `Le coach est un accompagnateur, il n’est pas un conseiller ni quelqu’un va donner des directives. Il aide l’individu à trouver ce qui est juste, bon et fructeux pour lui.`,
        ],
      },
      howIsASession: {
        title: 'Comment se passe une séance',
        items: [
          `Un entretien de coaching dure environ 1h. Selon le sujet traité, cela peut se dérouler sur 7 à 9 séances. Parfois une ou deux séances suffisent. Généralement un entretien a lieu environ une fois par semaine ou tous les quinze jours selon la nécessité.`,
          `C’est un temps mis à disposition de la personne où le coach, par son écoute et ses questionnements, accompagne le coaché dans un cheminement. Il utilise des outils très précis choisis en fonction des besoins de la personne.`,
          `Lors des premières séances le but est d'explorer la situation présente, d’avoir une vision globale et de chercher à clarifier les défis actuels.`,
          `Après cette première étape, le coach aide son client à fixer des objectifs clairs, en accord avec ce qui est important pour lui. Dans ce parcours il se peut que surviennent des obstacles, ainsi le coach aidera la personne à les surmonter en l’aidant à se connecter à ses ressources et à les développer.`,
          `Avec l’aide du coach, le coaché aura une idée claire de quels sont les enjeux de ses choix et pourra définir les étapes qui lui permettront d’atteindre avec succès ses objectifs.`,
        ],
      },
      whatCanCoachingGiveYou: {
        title: 'Qu’est-ce que le coaching pourra vous apporter',
        items: [
          `Le coaching que je vous propose vous aidera à trouver des solutions concrètes aux défis qui se présentent à vous.`,
          `Que ce soit des difficultés professionnelles, relationnelles ou personnelles auxquelles vous faites face, le coaching est un moyen efficace pour vous aider à sortir de circonstances où vous vous sentez peut-être bloqué, limité ou confus.`,
        ],
      },
      togetherWeWillBeAbleTo: {
        title: 'Ensemble nous pourrons',
        items: [
          `Clarifier votre situation`,
          `Définir des objectifs précis`,
          `Mobiliser et développer vos ressources afin d'atteindre vos buts`,
          `Dépasser les obstacles et limitations`,
        ],
      },
      butAlso: {
        title: 'Mais aussi',
        items: [
          `Vous connecter à vos véritables désirs et aspirations`,
          `Découvrir vos talents`,
          `Reconnaître vos valeurs et ce qui a réellement de l’importance pour vous`,
          `Démêler les conflits intérieurs et les difficultés externes`,
        ],
      },
      myCoachingWillBringYou: {
        title: 'Mon coaching vous apportera',
        items: [
          `Une vision claire de votre situation`,
          `Des solutions pour retrouver l’harmonie`,
          `Un soutien dans une période de transition`,
          `Un appui dans une étape difficile`,
          `Une assistance pour faire les meilleurs choix`,
          `Des outils concrets et précis pour votre santé`,
        ],
      },
    },
    yoga: {
      yoga: {
        title: 'Yoga',
        items: [
          {
            items: [
              `Yoga signifie union. C’est l’union entre le corps, l’âme et l’esprit. L’union avec le Divin, notre moi supérieur et la conscience de tout ce qui nous entoure. C’est l’union entre le souffle, le mouvement et la présence. C’est cette union qui crée l’harmonie.`,
              `Le yoga est une pratique qui a de nombreuses facettes. Il est accessible à tous. Pratiquer le yoga c’est prendre soin de soi dans une réelle écoute avec bienveillance et respect.`,
            ],
          },
          {
            title: 'Yoga hatha-vinyasa',
            items: [
              `Le yoga hatha-vinyasa est une pratique plutôt yang qui est dynamique et douce à la fois. Ses bienfaits sont remarquables sur les plans physique, mental émotionnel et spirituel. Il permet d’aligner sa posture, de renforcer sa musculature, de densifier son ossature, de s’assouplir et d’améliorer sa concentration. Il permet également d’être plus en phase avec soi-même. Au-delà du niveau physique, vous pouvez éliminer les schémas mentaux négatifs et  progresser dans une méditation en mouvement avec un esprit calme et clair.`,
            ],
          },
          {
            title: 'Yin yoga',
            items: [
              `Le Yin yoga est une pratique méditative de pleine conscience qui calme le système nerveux. Sa spécificité est de travailler dans l’immobilité, chaque posture étant tenue environ cinq minutes, de façon à nettoyer les canaux énergétiques grâce à l’acupression. Elle régénère les organes en stimulant les méridiens. Cette pratique cible les articulations, les os et fait travailler les tissus conjonctifs en profondeur et permet à l’énergie vitale de mieux circuler.`,
            ],
          },
          {
            title: 'Méditation guidée',
            items: [
              `La méditation guidée que je vous propose est un moment de relaxation intense qui vous permet de vous connecter à votre intériorité. Elle vous ouvre un espace pour relâcher les tensions physiques et émotionnelles et vous aide à intégrer avec plus de fluidité les changements que vous créez dans votre vie. Elle est aussi un espace de guérison et d’alignement énergétique.`,
            ],
          },
        ],
      },
      health: {
        title: 'Santé',
        items: [
          `Lorsque nous désirons atteindre un objectif de santé, nous recherchons souvent des conseils avisés. Ce que nous voulons, ce n'est pas un programme rigide pour tous mais un processus qui nous convient personnellement à tous les niveaux et que nous pouvons suivre à long terme et nous donner le résultat durable que nous souhaitons.`,
          `En utilisant le coaching, nous prenons en compte tous les domaines de votre vie (vos besoins, vos priorités, votre emploi du temps, vos goûts, vos habitudes, etc ...) afin de discerner quelles sont les choses qui vous empêchent d'atteindre votre but et mettre en place un plan qui correspondra à votre physiologie, à votre emploi du temps et aux changements que vous êtes prêt à apporter.`,
          `Ensemble, nous trouverons la routine qui vous convient, y compris les bonnes habitudes alimentaires, le type d'exercice dont vous avez besoin, comment prendre soin de vous et l'état d'esprit dont vous avez besoin.`,
        ],
      },
      body: {
        title: 'Corps',
        items: [
          `Notre corps réagit à toutes nos pensées, émotions et expériences. Chaque cellule de notre corps a une mémoire. En nous connectant à notre corps et en l’aimant comme il le mérite, nous nous engageons dans un processus de guérison profonde.`,
          `Si nous voulons devenir la meilleure version de nous-même, nous devons absolument prendre soin de notre corps.`,
          `Avoir confiance en la vie est également quelque chose qui se traduit dans le corps. Nous pouvons travailler de la tête au corps ou du corps à la tête. J'aime travailler dans les deux sens.`,
          `En tant que professeure de yoga certifiée et ayant  de l’expérience dans le domaine du mouvement (danse, pilates, théâtre physique, improvisation), je veux aider les gens à trouver un équilibre sain dans leur corps. Je veux les aider à se sentir profondément connectés.`,
          `Mon désir est de travailler ensemble dans des séances personnelles et après cela, je peux donner des exercices spécifiques à pratiquer seul pour améliorer la posture, libérer les émotions et le stress, renforcer le corps là où il en a besoin, améliorer la souplesse et gagner en confiance. Je veux aussi aider les gens à trouver une plus grande liberté et fluidité dans leurs mouvements pour améliorer leur présence et leur plaisir de vivre.`,
          `Lors d'une séance de coaching, on prend conscience de beaucoup de choses, Il y a une dimension  magique lorsque tout ce processus mental et émotionnel peut être digéré et incarné profondément dans le corps.`,
          `Sur demande, il est possible de faire une session en petit groupe. Il peut s'agir d'une séance de yoga ou d'une séance d'expression physique. Quoi qu'il en soit, je vais créer un temps qui correspondrait aux besoins des participants, y compris des échauffements, des méditations, des mouvements et de l'improvisation afin que les gens éprouvent un plus grand sentiment de liberté, une plus grande confiance et explorent leur propre créativité.`,
        ],
      },
    },
    guidance: {
      supportForHealing: {
        title: 'Accompagnement vers la guérison',
        items: [
          `Au cours des événements de la vie, il arrive souvent que nous expérimentions des émotions très intenses et que ces dernières n’ont pas pu être vécues pleinement. Ces émotions se cristallisent alors dans le corps et nous empêchent de vivre pleinement et librement.`,
          `L’accompagnement que je propose tient compte de l’être dans son entier, c’est pourquoi j’aime utiliser différentes approches adaptées aux sensiblités de chacun. Dans les pratiques de libération émotionnelles proposées, je demeure à l’écoute des besoins des personnes que j’accompagne et veille à apporter un grand soin à toutes les étapes du processus. Une intégration est prévue à l’issue de ces séances et également un débriefing programmé afin de définir l’étape suivante.`,
        ],
      },
      alchemicalBreath: {
        title: 'Respiration Alchimique',
        items: [
          `La Respiration Alchimique (Alchemy of Breath®) est une pratique de respiration soutenue, consciente et connectée qui permet un nettoyage physique et énergétique en profondeur. Cette pratique ancestrale intensifie notre niveau d’énergie et amène à une transformation des émotions engrammées dans le corps en libérant les mémoires cellulaires. Elle permet une profonde exploration de soi en accédant à un état de conscience élargi de manière naturelle, tout simplement en respirant.`,
          `La Respiration Alchimique offre la possibilité de rentrer en relation avec les profondeurs de l’inconscient, faisant émerger les informations provenants des niveaux physique, émotionnel, mental et spirituel. Cette ouverture de conscience active notre capacité naturelle d’auto-guérison en nous permettant de revivre, d’intégrer et de transformer les expériences et les mémoires qui sont à la source de nos souffrances. Tout comme l’alchimie vise à transformer le plomb en or, la Respiration Alchimique nous apprend à transmuter nos énergies lourdes et douloureuses en énergies libres et rayonnantes.`,
        ],
      },
      metatherapy: {
        title: 'Métathérapie',
        items: [
          `La Métathérapie est une pratique qui nous permet d’aller en pleine conscience à la rencontre de nos zones de souffrances et de transformer ces blessures émotionnelles. Il s’agit d’un processus d’introspection personnel qui passe par le ressenti corporel. C’est une méthode concrète et pragmatique qui agit au plus profond de nous. C’est pourquoi elle amène une solution rapide, efficace et stable dans le temps.`,
          `Le praticien en métathérapie accompagne la personne à aller par elle-même dans son ressenti. Il lui montre comment délier, résoudre et transformer ses espaces de souffrance grâce à l’accueil, à l’acceptation et au pardon. C’est un travail d’amour pour soi-même qui va fluidifier ce qui est bloqué et nous réaligner sur notre véritable potentiel.`,
          `Et plus que tout, la métathérapie nous enseigne les clés de la transformation des énergies et des émotions bloquées à l’intérieur de nous. Une fois la pratique intégrée nous pouvons devenir indépendant dans notre travail. C’est un processus d’autonomisation et de responsabilisation, dans le chemin de guérison et d’éveil.`,
        ],
      },
      intuitiveReadings: {
        title: 'Lecture intuitive des énérgies',
        items: [
          `Dans cette pratique, je me connecte ma guidance divine et à votre énergie afine d’obtenir des éclaircissments et des encouragments dans votre parcours de vie et pour votre situation particulière. Il ne s’agit pas de prédiction mais bien de lecture énergétique. Comme support j’utilise des cartes oracle et de tarot. Une partie de la lecture se fait par l’intuition et les énergies des cartes et certains sont directement canalisés. Ces lectures visent à vous aider à vous aligner, vous connecter à votre propre guidance et à revenir à un état de sérénité par rapport à votre chemin de vie.`,
        ],
      },
    },
    about: {
      vision: {
        title: 'Vision',
        items: [
          `Il y a l'intérieur de chaque individu une combinaison exclusive de talents et de trésors parfois insoupçonnés. L’être véritablement heureux est celui qui a appris à les exprimer et apporte au travers de ces derniers une contribution unique au monde. Je crois que le succès d’une vie fructueuse, fait écho à une vie réellement satisfaisante, elle doit donc être empreinte d’harmonie et de justesse.`,
          `Nous sommes des êtres complexes et multicolores où différentes dimensions s’expriment et cherchent sans cesse à s’accorder. Afin d’avancer avec agilité et harmonie, nous avons besoin de prendre conscience de notre alignement corps âme esprit et de considérer tous les aspects de notre personne et de nos vies et de les nourrir de manière appropriée selon nos priorités du moment. Nous ne pouvons pas nous permettre de négliger une partie de notre être ou de notre existence.`,
          `Parfois nos vies ont simplement besoin de quelques petits ajustments afin de retrouver une forme d’équilibre et d’autres fois de plus grands changements sont nécessaires. Chaque individu sait à l’intérieur de lui quelle est la meilleure solution et la voie la plus adaptée. C’est pour moi une véritable passion d'aider les gens à se connecter à leur intériorité, à embrasser leur véritable identité, découvrir de nouveaux possibles, oser aller de l’avant et devenir pleinement vivants dans tous les sens du terme.`,
        ],
      },
      biography: {
        title: 'Biographie',
        items: [
          `Ayant toujours été interpelée par le mystère profond de l'existence, je me suis lancée très tôt en quête d’une vie harmonieuse et pleine de sens. J’ai parcouru un long chemin d’exploration et de transformation. Passionnée de développement personnel, de spiritualité et de psychologie, j’ai puisé dans ces domaines les ressources nécessaires pour aller toujours plus vers le meilleur de moi-même. À diverses reprises, j’ai eu l’occasion d’enseigner et de créer des espaces permettant à des personnes de renouer avec elles-mêmes et de dépasser certaines de leurs limites.`,
          `En 2014, je décide  de me former professionnellement afin de pouvoir transmettre à d’autres les richesses accumulées le long de mon parcours. Je commence par une formation de professeure de yoga, car pour moi, tout chemin de vie sensé débute par une connexion intérieure authentique. Je relève aussi l’importance d’une cohérence entre le vécu intérieur et la manière dont il s’exprime et se manifeste de manière concrète à l’extérieur.`,
          `Fascinée par l'interaction directe qu'il y a entre le corps l'âme et l'esprit, j'aime considérer la personne dans sa globalité et sa diversité. Je continue à me former avec la PNL et le coaching et découvre, par ce biais, de nouvelles possibilités de changement. Au travers de mes études et de mon expérience, j’ai acquis de bonnes connaissances en matière de santé et de nutrition. Grâce à ce parcours, je suis heureuse d'avoir pu trouver mon chemin vers une belle harmonie et une authentique connexion à mon essence et à ce qui donne un sens à ma vie.`,
          `C’est aujourd’hui un honneur pour moi de pouvoir accompagner des personnes sur leur chemin de vie. Mon travail consiste à les aider à trouver toutes les ressources dont elles ont besoin pour aller vers le meilleur d’elles-mêmes et créer la vie qui leur correspond vraiment et qui saura les satisfaire pleinement.`,
        ],
      },
    },
    contact: {
      pageHeading:
        'Offez-vous le cadeau d’aller vers le meilleur de vous-même et de vous créer une vie qui résonne pleinement avec qui vous êtes.',
      contactHeading: 'Contact',
      emailHeading: 'Email',
      phoneAndWhatsappHeading: 'Téléphone et whatsapp',
      addressHeading: 'Adresse',
      pricesHeading: 'Prix',
      pricesSubheading:
        'N’hésitez pas à me contacter pour toute question ou précision supplémentaire. Si vous le souhaitez nous pouvons convenir d’un entretien de 15 mins par video conférence sans engagement de votre part.',
      prices: [
        {
          type: 'session',
          text: '1 séance de coaching',
          duration: '1 heure',
          price: '120',
        },
        {
          type: 'session',
          text: 'Package de 7 séances de coaching',
          duration: '1 heure',
          price: '770',
        },
        {
          type: 'session',
          text: '1 séance de coaching et de méditation guidée',
          duration: '1 heure 30 minutes',
          price: '160',
        },
        {
          type: 'session',
          text: 'Package de 7 séances de coaching et de méditation guidée',
          duration: '1 heure 30 minutes',
          price: '1020',
        },
        {
          type: 'package',
          title:
            'Package Sérénité: Spécialement conçu pour un objectif santé et/ou pour la gestion du stress et des émotions',
          price: '1070',
          sessions: [
            { text: '5 séances de coaching', duration: '1 heure' },
            {
              text: '5 séances de yoga, respiration et méditation guidée',
              duration: '1 heure',
            },
            {
              text: '1 séance de conseil',
              duration: '45 minutes',
              video: true,
            },
            {
              text: '2 séances de débriefing + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'package',
          title:
            'Package Harmonie: Spécialement conçu pour prendre votre vie en main',
          price: '1400',
          sessions: [
            { text: '7 séances de coaching', duration: '1 heure' },
            { text: '5 séances de yoga', duration: '1 heure' },
            { text: '2 séances de méditation guidée', duration: '1 heure' },
            {
              text: '1 séance de conseil',
              duration: '45 minutes',
              video: true,
            },
            {
              text: '3 séances de débriefing + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'package',
          title:
            'Package Lumière: Spécialement conçu lors d’un changement de vie majeur et/ou d’un nouvel objectif important',
          price: '1600',
          sessions: [
            { text: '8 séances de coaching', duration: '1 heure' },
            { text: '8 séances de yoga', duration: '1 heure' },
            { text: '3 séances de méditation guidée', duration: '1 heure' },
            {
              text: '4 séances de débriefing + motivation',
              duration: '30 minutes',
              video: true,
            },
          ],
        },
        {
          type: 'session',
          text: '1 cours de yoga individuel',
          duration: '1 heure',
          price: '80',
        },
      ],
      houseCall:
        'Possibilité de donner des cours en petit groupe sur demande (prix à convenir)',
      contactForPrice: `Pour les sessions de respiration alchimique, de métathérapie et de lecture intuitive contactez-moi en privé afin de convenir de la formule la plus adaptée à vos besoins.`,
    },
  },
}
