import React, { PureComponent, Fragment } from 'react'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import browserLang from 'browser-lang'

class RedirectComponent extends PureComponent {
  componentDidMount() {
    if (typeof window !== undefined) {
      const { languages, fallback } = this?.props?.data?.site?.siteMetadata?.i18n
      let { pathname } = this?.props?.location || ''
      const { pathPrefix } = this?.props?.data?.site
      if (pathPrefix && pathPrefix !== '') {
        pathname = pathname.replace(new RegExp(`^${pathPrefix}`, ''))
      }

      const detectedLang =
        window.localStorage.getItem('language') ||
        browserLang({ languages, fallback })

      const redirectUrl = withPrefix(`/${detectedLang}${pathname}`)
      window.localStorage.setItem('language', detectedLang)
      window.location.replace(redirectUrl)
    }
  }

  render() {
    return <Fragment />
  }
}

// TODO: what is up with this query?
const Redirect = props => (
  <StaticQuery
    query={graphql`
      query LanguageQuery {
        site {
          pathPrefix
          siteMetadata {
            i18n {
              languages
              fallback
            }
          }
        }
      }
    `}
    render={data => <RedirectComponent data={data} {...props} />}
  />
)

export { Redirect }
