import React, { forwardRef } from 'react'
import { Flex } from 'components'

const Container = forwardRef((props, ref) => (
  <Flex
    ref={ref}
    {...props}
    // TODO: one day we should set _ to 86% and fix the
    // rest of the margins and paddings in the content
    maxWidth={{ _: '100%', sm: 'container' }}
    marginX={'auto'}
    flexDirection={'column'}
    label={'Container'}
  />
))
Container.propTypes = Flex.propTypes

export { Container }
