import React from 'react'
import { keyframes,css } from '@emotion/core'

const throb = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`

const Throb = ({ delay = '0s', duration = '2s', children }) => (
  <span
    css={css`
      & > *:hover {
        animation: ${duration} linear ${delay} infinite normal forwards running
          ${throb};
      }
    `}
  >
    {children}
  </span>
)

const fadeIn = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const FadeIn = ({ delay = '0s', duration = '2s', children }) => (
  <span
    css={css`
      & > * {
        animation: ${duration} ease-in ${delay} 1 normal forwards running
          ${fadeIn};
        opacity: 0;
      }
    `}
  >
    {children}
  </span>
)

export { Throb, FadeIn }
