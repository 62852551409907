import React from 'react'
import { Flex } from 'components'

const Hero = ({
  backgroundImage,
  backgroundColor = 'inherit',
  backgroundPosition = 'center 33%',
  backgroundSize = '100% auto',
  children,
}) => (
  <Flex
    as={'section'}
    width={'100vw'}
    minHeight={{ _: '200px', md: '400px' }}
    justifyContent={'center'}
    alignItems={'center'}
    textAlign={'center'}
    backgroundRepeat={'no-repeat'}
    backgroundSize={backgroundSize}
    backgroundColor={backgroundColor}
    backgroundImage={`url(${backgroundImage})`}
    backgroundAttachment={{ _: 'inherit', sm: 'fixed' }}
    backgroundPosition={backgroundPosition}
  >
    {children}
  </Flex>
)

export { Hero }
