import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'

import locales from 'config/locales'
import i18n from 'config/i18n'
import 'normalize.css/normalize.css'
import 'styles/main.css'

import { Header, Footer, Box, LanguageContext } from 'components'
import theme from '../config/theme'

const fonts = theme.googleFonts.join('|')

const Layout = ({ title = '', language = i18n.fallback, children }) => {
  const locale = locales[language]
  title = `${locale.siteTitle}${title && ` | ${title}`}`
  return (
    <ThemeProvider theme={theme}>
      <LanguageContext.Provider value={language}>
        {/* TODO: add description and keywords */}
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: 'Sample' },
          ]}
        >
          <html lang={language} />

          <link
            href={`//fonts.googleapis.com/css?family=${fonts}`}
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>

        <Header menu={locale.menu} />

        <Box as={'main'}>{children}</Box>

        <Footer />
      </LanguageContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
