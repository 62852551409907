import React, { createContext, useContext } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import * as R from 'ramda'

import i18n from 'config/i18n'
import { Box } from 'components'
import GlobeSVG from 'images/globe.svg'

const LanguageContext = createContext(i18n.fallback)

const languages = {
  en: 'English',
  fr: 'French',
}

const pathMatcher = R.match(
  new RegExp(`/(?:${R.join('|', R.keys(languages))})/(.*)?`)
)

const LanguageChanger = ({minimal = false, ...props}) => {
  const { pathname } = useLocation()
  const language = useContext(LanguageContext)
  const match = pathMatcher(pathname)
  const currentPath = match?.[1] || ''

  const label = minimal ?
    (language === 'en' ? 'fr' : 'en' ) :
    (language === 'en' ? 'French' : 'English' )

  return (
    <Box
      {...props}
      as={Link}
      to={`/${language === 'en' ? 'fr' : 'en'}/${currentPath}`}
      display={'flex'}
      alignItems={'center'}
      textDecoration={'none'}
      color={'primary.dark'}
      fill={'primary.dark'}
      styled={{
        '&:hover': {
          fill: 'primary.pink',
          color: 'primary.pink',
        },
      }}
    >
      <GlobeSVG
        css={{
          width: '17px',
          height: '17px',
        }}
      />
      <Box marginLeft={1} textTransform={minimal ? 'uppercase' : 'initial'} fontSize={'header'}>
        {label}
      </Box>
    </Box>
  )
}

export { LanguageChanger, LanguageContext }
