import React from 'react'
import { Box } from 'components'

import logoWithText from 'images/logo-flower-text.png'
import logoNoText from 'images/logo-flower.png'

const Logo = ({ height = '75px', text = true }) => (
  <Box as={'img'} src={text ? logoWithText : logoNoText} height={height} alt={'Sankalpa'} />
)

export { Logo }
